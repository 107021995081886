import React, { useEffect } from 'react'
import WebService from '../../Services/WebService'

const Dummy = () => {
    useEffect(()=>{
        window.location.href = WebService.getBaseUrl("wp")
    },[])
  return (
    <></>
  )
}

export default Dummy
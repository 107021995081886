import { useState } from "react";
import Logo from "../../assets/images/shan_and_co_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./login.scss";
import { Controller, useForm } from "react-hook-form";
import { Label } from "../Common/Label/Label";
import WebService from "../../Services/WebService";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { SET_SIGNUP_DATA, setDataInRedux } from "../../action/CommonAction";
import { GoAlertFill } from "react-icons/go";
const Signup = () => {
  const url = WebService.getBaseUrl("wp")
  const dispatch: Dispatch<any> = useDispatch();
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    watch,
    getValues,
    register,
  } = useForm<any>({});
  const watchAllFields = watch();
  const navigate = useNavigate();
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const passwordRegex =
    /^^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
  const mobileRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  const companyRegex = /^[A-Za-z][A-Za-z_ ]{2,60}$/;

  const nameRegex = /^[A-Za-z][A-Za-z_ ]{2,30}$/;

  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword1, setShowPassword1] = useState<boolean>(false);

  const onSignup = (data: any) => {
    WebService.postAPI({
      action: "signup",
      body: data,
      id: "signup_btn"

    })
      .then((res: any) => {
        toast.success(res?.message);
        dispatch(setDataInRedux({ type: SET_SIGNUP_DATA, value: data }));
        navigate("/otp");
        // navigate("/login")
      })
      .catch((e) => {

      });
  };


  return (
    <>
      <div className="login-page">

        {/* <div
          className="alert alert-block alert-danger align-items-center "
          style={{ width: "600px", margin: "auto" }}
        >
          <div className="align-items-center ">
            <div className="d-flex mr-3" style={{ fontSize: "24px" }}>
              <span className="me-2">
                <GoAlertFill />
              </span>
              <strong className="align-items-center d-flex ">Error</strong>
            </div>
            <div>
              An error occurred while saving your changes. <br />
              You cannot signup with emails from GMAIL.COM.
            </div>
          </div>
        </div> */}

        <div className="row justify-content-center min-vh-100 align-items-center mx-0">
          <div className="col-lg-5 d-flex align-items-center justify-content-center">
            <div className="w-100 login-card rounded-4">
              <form onSubmit={handleSubmit(onSignup)}>
                <div className="px-lg-5 px-3 py-4">
                  <div className="mb-4 text-center">
                    <img src={Logo} width="186" height={30} alt="Logo" />
                  </div>
                  <div className=" ">
                    <h1 className="text-light mb-1 font-bold text-center h2">
                      Access 1000+Documents
                    </h1>
                    <p className="font-16 text-light text-center mb-4">
                      Create a free account to get started.
                    </p>
                    <Row className="mb-3">
                      <Col lg={12}>
                        <h4 className="text-light mb-3">Information</h4>
                      </Col>
                      {/* First Name */}
                      <Col xs={6}>
                        <Controller
                          control={control}
                          name="first_name"
                          rules={{
                            required: "Please Enter First Name",
                            pattern: {
                              value: nameRegex,
                              message:
                                "Between 3 to 30 characters, letters only.",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched, isDirty },
                          }) => (
                            <div className="mb-4">
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  placeholder="First Name"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              </Form.Group>
                              {(errors["first_name"]?.message ||
                                Boolean(errors["first_name"]?.message) ||
                                (isTouched && !watchAllFields.first_name) ||
                                (watchAllFields.first_name &&
                                  !nameRegex.test(
                                    watchAllFields.first_name
                                  ))) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.first_name?.message ||
                                      watchAllFields.first_name
                                        ? "Between 3 to 30 characters, letters only"
                                        : "Please Enter First Name."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                      {/* Last Name */}
                      <Col xs={6}>
                        <Controller
                          control={control}
                          name="last_name"
                          rules={{
                            required: "Please Enter Last Name",
                            pattern: {
                              value: nameRegex,
                              message:
                                "Between 3 to 30 characters, letters only.",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched },
                          }) => (
                            <div className="mb-4">
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  placeholder="Last Name"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              </Form.Group>
                              {(errors["last_name"]?.message ||
                                Boolean(errors["last_name"]?.message) ||
                                (isTouched && !watchAllFields.last_name) ||
                                (watchAllFields.last_name &&
                                  !nameRegex.test(
                                    watchAllFields.last_name
                                  ))) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.last_name?.message ||
                                      watchAllFields.last_name
                                        ? "Between 3 to 30 characters, letters only"
                                        : "Please Enter Last Name."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                      {/* Company Name */}
                      <Col lg={12}>
                        <Controller
                          control={control}
                          name="company_name"
                          rules={{
                            required: "Please Enter Campany Name",
                            pattern: {
                              value: companyRegex,
                              message:
                                "Between 3 to 60 characters, letters only.",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched },
                          }) => (
                            <div className="mb-4">
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  placeholder="Company Name"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              </Form.Group>
                              {(errors["company_name"]?.message ||
                                Boolean(errors["company_name"]?.message) ||
                                (isTouched && !watchAllFields.company_name) ||
                                (watchAllFields.company_name &&
                                  !companyRegex.test(
                                    watchAllFields.company_name
                                  ))) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.company_name?.message ||
                                      watchAllFields.company_name
                                        ? "Between 3 to 60 characters, letters only"
                                        : "Please Enter Company Name."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col lg={12}>
                        <h4 className="text-light mb-3">Account Credentials</h4>
                      </Col>
                      <Col lg={12}>
                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            required: "Please Enter Email",
                            pattern: {
                              value: emailRegex,
                              message: "Enter valid email address",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched, isDirty },
                          }) => (
                            <div className="mb-4">
                              <Form.Group className="mb-3">
                                <Form.Control
                                  type="text"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  placeholder="Work Email"
                                />
                              </Form.Group>
                              {(errors["email"]?.message ||
                                Boolean(errors["email"]?.message) ||
                                (isTouched && !watchAllFields.email) ||
                                (watchAllFields.email &&
                                  !emailRegex.test(watchAllFields.email))) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.email?.message ||
                                      watchAllFields.email
                                        ? "Enter valid email address"
                                        : "Please Enter Email."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={6}>
                        {/* Password */}
                        <Controller
                          control={control}
                          name="password"
                          rules={{
                            required: "Please Enter Password",
                            pattern: {
                              value: passwordRegex,
                              message:
                                "Between 8 to 20 characters and at least one upper case, lower case, number and special character.",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched },
                          }) => (
                            <div className="form-group mb-3">
                              <div className="input-group mb-2">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control border-end-0 border-start-0"
                                  placeholder="Password"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                                <span
                                  className="input-group-text text-secondary bg-white border-start-0 cursor-pointer"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <BsEyeSlash size={16} />
                                  ) : (
                                    <BsEye size={16} />
                                  )}
                                </span>
                              </div>
                              {(errors["password"]?.message ||
                                Boolean(errors["password"]?.message) ||
                                (isTouched && !watchAllFields.password) ||
                                (watchAllFields.password &&
                                  !passwordRegex.test(
                                    watchAllFields.password
                                  ))) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.password?.message ||
                                      watchAllFields.password
                                        ? "Between 8 to 20 characters and at least one upper case, lower case, number and special character."
                                        : "Please Enter Password."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                      <Col lg={6}>
                        {/* Confirm Password */}
                        <Controller
                          control={control}
                          name="comfirmpassword"
                          rules={{
                            required: "Please Enter Password",
                            validate: (value: any) => {
                              const { password } = getValues();
                              return (
                                password === value || "Passwords must match"
                              );
                            },
                          }}
                          render={({
                            field: { onChange, onBlur },
                            fieldState: { isTouched },
                          }) => (
                            <div className="mb-3">
                              <div className="input-group mb-2">
                                <input
                                  type={showPassword1 ? "text" : "password"}
                                  className="form-control border-end-0 border-start-0"
                                  placeholder="Confirm Password"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                                <span
                                  className="input-group-text text-secondary bg-white border-start-0 cursor-pointer"
                                  onClick={() =>
                                    setShowPassword1(!showPassword1)
                                  }
                                >
                                  {showPassword1 ? (
                                    <BsEyeSlash size={16} />
                                  ) : (
                                    <BsEye size={16} />
                                  )}
                                </span>
                              </div>
                              {(errors["comfirmpassword"]?.message ||
                                Boolean(errors["comfirmpassword"]?.message) ||
                                (isTouched &&
                                  !watchAllFields.comfirmpassword) ||
                                (watchAllFields.comfirmpassword &&
                                  watchAllFields.password !=
                                    watchAllFields.comfirmpassword)) && (
                                <div className="login-error">
                                  <Label
                                    title={
                                      errors.comfirmpassword?.message ||
                                      watchAllFields.comfirmpassword
                                        ? "Passwords Must Match"
                                        : "Please Enter Confirm Password."
                                    }
                                    modeError={true}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex gap-2 mb-1">
                      <Form.Check
                        id="remember"
                        label="I accept the"
                        className="text-white"
                        checked={isAccepted}
                        onChange={() => setIsAccepted(!isAccepted)}
                      />
                      <span>
                        <a
                      href={`${url}terms-and-conditions`}
                      target="_blank"
                          className="text-brand"
                        >
                          Terms & Conditions
                        </a>{" "}
                        <span className="text-light">of Shan & Co.</span>
                      </span>
                    </div>
                    {isValid && !isAccepted && (
                      <div className="login-error mb-2">
                        <Label
                          title={"Please Accept Terms and Conditions"}
                          modeError={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-center ">
                    <Button
                      type="submit"
                      id="signup_btn"
                      className="btn btn-brand-1 w-100"
                      disabled={!isValid || !isAccepted}
                    >
                      Signup
                    </Button>
                    <p className="text-white mt-3 mb-0">
                      Back to{" "}
                      <Link to="/login" className="text-brand">
                        login
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signup;

import loader from "../../../assets/images/loader.gif";
const PageLoader = () => {
    return (
        <div className="">
            <div></div>
            <div style={{ textAlign: "center", marginTop: "10%" }}>
                <img
                    style={{ position: "relative" }}
                    src={loader}
                    alt="No loader found"
                />
                <div style={{ position: "relative", color: "white" }}>
                    Loading...
                </div>
            </div>
        </div>
    )
}
export default PageLoader;
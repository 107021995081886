import { Outlet } from "react-router-dom";
import PageHeader from "../components/Common/PageHeader";
import PageFooter from "../components/Common/PageFooter";
import Header from "./Header/Header";
 

const Main = () => {
  return (
    <>
      <Header />
      <div id="main-app" className="">
        <div className="app-body">
          <Outlet />
        </div>
      </div>
      <PageFooter />
    </>
  );
};

export default Main;

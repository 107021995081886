import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import WebService from "../../Services/WebService";


const PageFooter = () => {
  const url = WebService.getBaseUrl("wp")
  return (
    <>
      <footer className="site-footer py-lg-5 py-4">
        <Container>
          <Row className="text-white">
            <Col lg={4}>
              <h4 className="font-22 font-bold heading-font">US Office</h4>
              <p>
                4120 Bayhead Drive <br />
                Unit 102 <br />
                Bonita Springs, Florida 34134 <br />
                USA 
              </p>
            </Col>
            <Col lg={4}>
              <h4 className="font-22 font-bold heading-font">India Office</h4>
              <p>
              WeWork Futura, Sr No 133(P), CTS No 4944 <br />
              Magarpatta Road, Kirtane Baugh, <br />
              Magarpatta <br />
              Hadapsar <br />
              Pune <br />
              Maharashtra <br />
              INDIA - 411028ne <br />
              </p>
            </Col>
            <Col lg={4}>
              <h4 className="font-22 font-bold heading-font">Useful Links</h4>
              <a className="text-white" href={`${url}`} target="_blank">Home</a> <br />
               <a className="text-white" href={`${url}services/`} target="_blank">Services</a> <br />
               <a className="text-white" href={`${url}pricing-2/`} target="_blank">Pricing</a> <br />
               <a className="text-white" href={`${url}about/`} target="_blank">About Us</a> <br />
               <a className="text-white" href={`${url}contact-us/`} target="_blank">Contact Us</a> <br />
               <a className="text-white" href={`${url}/page-sitemap.xml`} target="_blank">Site Map</a> <br />
               <a className="text-white" href={`${url}`} target="_blank">Documents</a> <br />
               <a className="text-white" href={`${url}terms-and-conditions/`} target="_blank">Terms & Conditions</a> <br />

            </Col>
          </Row>
        </Container>
      </footer> 
    </>
  )
}
export default PageFooter


